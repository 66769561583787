.table-widget {
  margin: 0;
  padding: 0;
  border-spacing: 0;
  table-layout: fixed;
  border-collapse: collapse;
  & + .table-widget {
    margin: -1px 0 0;
  }
}
