.template-builder-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  background: linear-gradient(180deg, #ccdbfd 0%, #96adec 100%);

  .body {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    overflow: auto;

    .document-builder-library {
      z-index: 9;
    }

    main {
      flex: 1;
      display: flex;
      flex-direction: column;
      background: linear-gradient(180deg, #ccdbfd 0%, #96adec 100%);
      border-radius: 0.75rem;
      background: rgba(255, 255, 255, 0.8);
      padding: 0.5rem;

      .title {
        display: flex;
        height: 4.505rem;
        padding: 0.1875rem 1rem;
        flex-direction: row;
        align-items: center;

        .text {
          color: #19161d;
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 2rem;
        }
      }

      .floating-widgets {
        position: absolute;
        left: 0;
        top: 0;
        width: 10rem;
        height: 100%;
        backdrop-filter: blur(4px);
        background: #ffffff70;
        user-select: none;
      }

      .editor {
        display: flex;
        flex-direction: row;
        gap: 0.25rem;
        flex: 1 0 0;

        .library {
          width: 19.5rem;
          box-sizing: border-box;
          padding: 1rem 1.5rem 0.5rem 1rem;
          border-radius: 0.75rem;
          background: #fff;
          box-shadow:
            0px 0px 4px 0px rgba(0, 0, 0, 0.04),
            0px 4px 8px 0px rgba(0, 0, 0, 0.06);
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .search {
            display: flex;
            gap: 0.5rem;
            height: 2.5rem;
            border-radius: 2.5rem;
            border: 1px solid #ebebeb;
            background: #fff;
            align-items: center;
            padding: 0 0.5rem;

            .icon {
              width: 1rem;
              height: 1rem;
              background: url('/assets/template-builder/library-search-icon.svg') no-repeat center center;
              background-size: contain;
            }

            .text {
              border: none;
              outline: 0;
              padding: 0;
              font-size: 0.875rem;
              font-weight: 400;
              letter-spacing: 0.0125rem;

              &::placeholder {
                color: #53575a;
              }
            }
          }

          .group {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .label {
              .text {
                color: #19161d;
                font-size: 1rem;
                font-weight: 500;
                line-height: 1.5rem;
              }
            }

            .templates {
              display: flex;
              flex-direction: column;
              gap: 0.25rem;

              .template {
                display: flex;
                padding: 0.5rem;
                gap: 0.5rem;
                align-items: center;
                border-radius: 2.5rem;

                .icon {
                  display: flex;
                  width: 1.5rem;
                  height: 1.5rem;
                  padding: 0.25rem;
                  justify-content: center;
                  align-items: center;
                  flex-shrink: 0;
                  border-radius: 1.875rem;
                  background: #eee;

                  // img {
                  //   filter: invert(1) sepia(1) saturate(0.01) brightness(1);
                  // }
                }

                .name {
                  color: #19161d;
                  font-size: 0.875rem;
                  font-weight: 400;
                  letter-spacing: 0.0125rem;
                }

                &.active {
                  background: #ebf1fc;
                }
              }
            }
          }
        }

        .preview {
          flex: 1;
          border-radius: 0.75rem;
          background: #fff;
          box-shadow:
            0px 0px 4px 0px rgba(0, 0, 0, 0.04),
            0px 4px 8px 0px rgba(0, 0, 0, 0.06);
          display: flex;
          flex-direction: column;
          padding: 1rem;

          .editing-zone {
            flex: 1;
          }

          .actions {
            border-top: 1px solid #e5e5e5;
            padding: 1rem 0 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: 0.5rem;

            .button {
              height: 2rem;
              line-height: 2rem;
              padding: 0 0.75rem;
              border-radius: 2.5rem;
              font-size: 0.875rem;
              font-weight: 400;
              letter-spacing: 0.0125rem;
              display: block;
              text-decoration: none;

              &.cancel {
                background: #f2f2f2;
                color: #19161d;
              }

              &.submit {
                background: #19161d;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}