.document-builder-library {
  display: flex;
  padding: 0.75rem 0.75rem 0.5rem;
  flex-direction: column;
  gap: 1.125rem;
  border-radius: 0.75rem;
  background: #f4f9ff;

  .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 0.38rem;
    border-bottom: 1px solid #e5e5e5;
    gap: 0.38rem;

    .icon {
      width: 1.125rem;
      height: 1.125rem;
      background: url('/assets/document-builder/library-search-icon.svg')
        no-repeat center center;
      background-size: contain;
    }
    input.text {
      height: 1.125rem;
      line-height: 1.125rem;
      flex: 1;
      font-size: 0.75rem;
      font-weight: 400;
      outline: 0;
      border: 0;
      background: none;

      &::placeholder {
        color: #787879;
      }
    }
    .toc {
      display: flex;
      flex-direction: row;
      .label {
        color: #787879;
        font-size: 0.75rem;
        font-weight: 400;
        color: #787879;
      }
      &::after {
        content: '';
        display: flex;
        width: 0.9rem;
        height: 0.9rem;
        background: url('/assets/document-builder/library-toc-fold-icon.svg')
          no-repeat center center;
        background-size: contain;
        margin: 0.14rem 0 0;
      }
      &.unfold::after {
        transform: rotate(180deg);
      }
    }
  }
  .group {
    display: flex;
    flex-direction: column;
    gap: 1.125rem;

    .label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .text {
        color: #4d4d4d;
        font-size: 0.875rem;
        font-weight: 500;
      }

      &::after {
        content: '';
        display: flex;
        width: 1.125rem;
        height: 1.125rem;
        justify-content: center;
        align-items: center;
        background: url('/assets/document-builder/library-toc-fold-icon.svg')
          no-repeat center center;
        background-size: contain;
        margin: 0.14rem 0 0;
      }
    }
    .widgets {
      display: flex;
      flex-direction: column;
      gap: 0.875rem;

      .widget {
        display: flex;
        gap: 0.375rem;
        display: flex;
        padding: 0rem 0.5rem;
        text-decoration: none;
        cursor: default;
        user-select: none;
        align-items: center;

        .icon {
          width: 0.75rem;
          height: 0.75rem;
          object-fit: contain;
        }
        .name {
          color: #4d4d4d;
          font-size: 0.875rem;
          font-weight: 400;
        }
        &:hover {
          cursor: pointer;
          border-radius: 1.25rem;
          background: #fff;
          box-shadow:
            0px 0px 4px 0px rgba(0, 0, 0, 0.04),
            0px 4px 8px 0px rgba(0, 0, 0, 0.06);
        }
      }
    }
    &.fold {
      .label::after {
        transform: rotate(180deg);
      }
      .widgets {
        display: none;
      }
    }
  }
  .links {
    margin: auto 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;

    .link {
      height: 2.5rem;
      line-height: 2.5rem;
      flex: 1;
      border-radius: 2.5rem;
      font-size: 0.75rem;
      font-weight: 500;
      text-align: center;
      text-decoration: none;
    }
    .link.submit {
      background: #5e85ea;
      color: #fff;
    }
    .link.view {
      background: #ebabf5;
      color: #19161d;
    }
  }
}
