.orders-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  background: linear-gradient(180deg, #ccdbfd 0%, #96adec 100%);

  .body {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 0.25rem;

    main {
      flex: 1;
      display: flex;
      padding: 0.5rem;
      flex-direction: column;
      gap: 0.5rem;

      .title {
        display: flex;
        height: 4.5rem;
        padding: 0.1875rem 0.375rem;
        flex-direction: row;
        align-items: center;

        .text {
          color: #19161d;
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 2rem;
        }

        .add {
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2.5rem;
          background: url('/assets/orders-add-icon.svg') #19161d no-repeat
            center center;
          box-shadow:
            0px 0px 4px 0px rgba(0, 0, 0, 0.04),
            0px 4px 8px 0px rgba(0, 0, 0, 0.06);
          margin: 0 0 0 auto;
        }
      }

      .ribbon {
        display: flex;
        height: 2.75rem;
        padding: 0rem 0.375rem;
        justify-content: space-between;
        align-items: center;
        border-radius: 0.75rem;
        background: #fff;
        box-shadow:
          0px 0px 4px 0px rgba(0, 0, 0, 0.04),
          0px 4px 8px 0px rgba(0, 0, 0, 0.06);

        .views {
          display: flex;
          align-items: center;

          .view {
            display: flex;
            padding: 0rem 0.75rem;
            align-items: center;
            gap: 0.375rem;
            opacity: 0.3;
            text-decoration: none;

            .icon {
              width: 1.125rem;
              height: 1.125rem;
            }
            .text {
              color: #0d47fb;
              font-size: 0.75rem;
              font-weight: 400;
              line-height: 2.75rem;
            }

            &.active {
              opacity: 1;
            }
            &.gallery .icon {
              background: url('/assets/orders-gallery-icon.svg') no-repeat
                center center;
            }
            &.grid .icon {
              background: url('/assets/orders-grid-icon.svg') no-repeat center
                center;
            }
          }
        }
        .filters {
          display: flex;
          box-shadow:
            0px 0px 4px 0px rgba(0, 0, 0, 0.04),
            0px 4px 8px 0px rgba(0, 0, 0, 0.06);
          border-radius: 0.5rem;
          overflow: hidden;

          .filter {
            padding: 0rem 0.5rem;
            display: flex;
            height: 2.125rem;
            line-height: 2.125rem;
            color: #404040;
            font-size: 0.875rem;
            font-weight: 400;
            text-decoration: none;

            &.active {
              background: #d0e1f8;
              color: #146adc;
            }
          }
        }
        .search {
          display: flex;
          height: 2.125rem;
          padding: 0 0.5rem 0 1rem;
          flex-direction: row;
          align-items: center;
          border-radius: 0.5rem;
          border: 1px solid #c2c2c2;
          gap: 0.5rem;

          .icon {
            width: 1rem;
            height: 1rem;
            background: url('/assets/orders-search-icon.svg') no-repeat center
              center;
            background-size: contain;
          }
          .text {
            outline: 0;
            border: 0;
            font-size: 0.875rem;
            font-weight: 400;
            letter-spacing: -0.00963rem;

            &::placeholder {
              color: #9e9e9e;
            }
          }
        }
      }

      .cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        row-gap: 1rem;
        column-gap: 1rem;

        .card {
          display: flex;
          flex-direction: column;
          padding: 1rem 2rem 2rem 1rem;
          gap: 0.75rem;
          border-radius: 0.5rem;
          background: #fff;
          box-shadow:
            0px 0px 4px 0px rgba(0, 0, 0, 0.04),
            0px 4px 8px 0px rgba(0, 0, 0, 0.06);
          text-decoration: none;

          .heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.5rem;

            .name {
              color: #19161d;
              font-size: 1rem;
              font-weight: 800;
              letter-spacing: 0.0125rem;
            }
            .status {
              display: flex;
              height: 1.5rem;
              line-height: 1.5rem;
              padding: 0 0.75rem;
              font-size: 0.8125rem;
              font-weight: 400;
              border-radius: 6.25rem;

              &.pending {
                background: #fdf2d6;
                color: #c89616;
              }
              &.revising {
                background: #ffdbd8;
                color: #ff1300;
              }
              &.completed {
                background: #cbf0ee;
                color: #099d9d;
              }
            }
          }
          .fields {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .field {
              display: flex;
              flex-direction: column;
              gap: 0.25rem;

              .name {
                display: flex;
                align-items: center;
                gap: 0.25rem;

                .icon {
                  width: 0.75rem;
                  height: 0.75rem;
                  background: url('/assets/orders-field-icon.svg') no-repeat
                    center center;
                  background-size: contain;
                }
                .text {
                  color: #4d4d4d;
                  font-size: 0.625rem;
                  font-weight: 400;
                  line-height: 1rem;
                }
              }
              &.field.date .name .icon {
                background: url('/assets/orders-field-date-icon.svg') no-repeat
                  center center;
                background-size: contain;
              }
              &.field.person .name .icon {
                background: url('/assets/orders-field-person-icon.svg')
                  no-repeat center center;
                background-size: contain;
              }
              .value {
                color: #19161d;
                font-size: 0.75rem;
                font-weight: 500;
                line-height: 1.25rem;
                letter-spacing: 0.0125rem;
              }
              .status {
                display: flex;
                height: 1.25rem;
                line-height: 1.25rem;
                padding: 0 0.625rem;
                color: #000;
                font-size: 0.67706rem;
                font-weight: 400;
                margin: 0 auto 0 0;
                border-radius: 5.20831rem;

                &.pending {
                  background: #fdf2d6;
                }
                &.completed {
                  background: #cbf0ee;
                }
              }
              .bar {
                height: 0.25rem;
                background: #f2f2f2;
                border-radius: 0.25rem;
                overflow: hidden;

                .indicator {
                  height: 0.25rem;
                  background: #189383;
                }
              }
            }
          }
        }
      }
    }
  }
}
