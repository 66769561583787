.sidebar-component {
  display: flex;
  width: 15.5rem;
  padding: 0.5rem;
  flex-direction: column;
  align-items: stretch;
  flex-shrink: 0;
  gap: 0.5rem;
  border-radius: 0.75rem;
  background: #f4f9ff;

  .user {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    gap: 0.5rem;

    .avatar {
      width: 2.5rem;
      height: 2.5rem;
      overflow: hidden;
      border-radius: 50%;
      background: url('/assets/avatar.png') no-repeat center center;
      background-size: contain;
    }

    .info {
      .name {
        color: #19161d;
        font-size: 0.875rem;
        font-weight: 500;
      }

      .role {
        color: #86888a;
        font-size: 0.75rem;
        font-weight: 400;
      }
    }

    .dropdown {
      width: 1rem;
      height: 1rem;
      background: url('/assets/sidebar-user-dropdown.svg') no-repeat center center;
      background-size: contain;
      margin: 0 0 0 auto;
    }
  }

}