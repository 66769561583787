.table-cell-widget {
  line-height: 1.75rem;
  height: 1.75rem;
  border: 1px solid #dcdcdc;
  table-layout: fixed;

  .table-widget {
    border: none;

    .table-row-widget:first-of-type {
      .table-cell-widget {
        border-top: none;
      }
    }

    .table-row-widget:last-of-type {
      .table-cell-widget {
        border-bottom: none;
      }
    }

    .table-row-widget {
      .table-cell-widget:first-of-type {
        border-left: none;
      }

      .table-cell-widget:last-of-type {
        border-right: none;
      }
    }
  }
}