.notification-overlay {
  width: 35.9375rem;
  padding: 0rem 1.5rem 1.25rem 1.5rem;
  border-radius: 0.75rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  box-shadow:
    0px 0px 4px 0px rgba(0, 0, 0, 0.04),
    0px 4px 8px 0px rgba(0, 0, 0, 0.06);

  .header {
    padding: 0.875rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E7E7E7;

    .legend {
      color: #1E3BB3;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
    }

    .search {
      width: 9.44rem;
      padding: 0.25rem 0.5rem 0.25rem 0.75rem;
      border-radius: 0.75rem;
      border: 1px solid rgba(30, 59, 179, 0.24);
      display: flex;

      input.text {
        flex: 1;
        color: #9A9A9A;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        border: none;
        outline: 0;
        padding: 0;
      }

      .button {
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        background: url('/assets/notification-search-icon.svg') no-repeat center center;
      }
    }
  }

  .tabbar {
    padding: 0.88rem 0 1.12rem;
    border-bottom: 1px solid #E7E7E7;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .filters {
      display: flex;
      gap: 0.75rem;

      .filter {
        padding: 0.375rem 0.75rem;
        border-radius: 0.25rem;
        background: rgba(30, 59, 179, 0.06);
        color: #9A9A9A;
        font-size: 0.6875rem;
        font-style: normal;
        font-weight: 600;

        &.active {
          color: #fff;
          background: #1E3BB3;
        }
      }
    }

    .actions {
      display: flex;
      gap: 0.625rem;

      .action {
        border-radius: 0.375rem;
        border: 1px solid rgba(30, 59, 179, 0.24);
        padding: 0.25rem 1.25rem;
        color: #1E3BB3;
        font-size: 0.6875rem;
        font-style: normal;
        font-weight: 500;
        text-decoration: none;

        &.active {
          border-color: #1E3BB3;
        }
      }
    }
  }

  .notifications {
    display: flex;
    flex-direction: column;

    .notification {
      display: flex;
      align-items: center;
      padding: 1rem 0;
      gap: 0.5rem;
      border-bottom: 1px solid #E7E7E7;

      .checkbox {
        width: 1.25rem;
        height: 1.25rem;
        background: url('/assets/notification-unchecked-icon.svg') no-repeat center;
      }

      &.checked {
        .checkbox {
          background: url('/assets/notification-checked-icon.svg') no-repeat center;
        }
      }

      .type {
        padding: 0.12rem 0.37rem;
        color: #000;
        font-size: 0.6875rem;
        font-style: normal;
        font-weight: 400;
        border-radius: 0.25rem;
        border: 1px solid #000;
      }

      .name {
        color: #000;
        text-overflow: ellipsis;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        flex: 1;
        margin: 0 0 0 0.75rem;
      }

      .datetime {
        .date {
          color: #000;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
        }

        .time {
          color: #787879;
          font-size: 0.625rem;
          font-style: normal;
          font-weight: 400;
        }
      }

      &.read {
        .type {
          color: #787879;
          border-color: #787879;
        }

        .name {
          color: #787879;
        }

        .datetime {
          .date {
            color: #787879;
          }
        }
      }

    }
  }
}