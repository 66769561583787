@keyframes flash {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.agent-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .body {
    display: flex;
    flex: 1;
    border-radius: 0.75rem;
    overflow: hidden;

    .chat {
      // background: linear-gradient(180deg, #CCDBFD 0%, #96ADEC 100%);
      padding: 0.5rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .history {
        flex: 1;
        display: flex;
        flex-direction: column;
        background: #F6F6F6;
        filter: drop-shadow(7px 5px 28px rgba(0, 0, 0, 0.40));
        border-radius: 1.65rem;
        overflow: hidden;

        .heading {
          display: flex;
          flex-direction: row;
          align-items: center;
          border-radius: 1.65rem;
          background: linear-gradient(90deg, #203EBA -29.68%, rgba(32, 62, 186, 0.00) 100%);
          height: 3.3rem;
          padding: 0 0.5rem;
          gap: 0.3rem;

          .icon {
            width: 2.8125rem;
            height: 2.8125rem;
            background: url('/assets/agent-icon.svg') no-repeat center center;
          }

          .label {
            color: #fff;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 300;
          }

        }

        .messages {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding: 1rem;
          overflow: auto;

          .message {
            display: flex;
            align-items: flex-start;

            &.sender {
              .avatar {
                background: url('/assets/avatar.png') no-repeat center center;
                background-size: contain;
              }
            }

            &.receiver {
              .avatar {
                background: url('/assets/agent-icon.svg') no-repeat center center;
                background-size: contain;
              }
            }

            .avatar {
              min-width: 3rem;
              min-height: 3rem;
              overflow: hidden;
              border-radius: 50%;
            }

            .bubble {
              padding: 1rem 1rem 0;
              line-height: 1.5rem;

              p {
                margin: 0;
                padding: 0;
              }
            }


            &.loading .bubble:empty::after,
            &.loading .bubble>*:last-child::after {
              content: '';
              width: 1.25rem;
              height: 1.25rem;
              background: #ED2224;
              display: inline-block;
              border-radius: 100%;
              vertical-align: middle;
              margin: -0.15rem 0 0 0.2rem;
              animation: flash 0.5s ease-in-out 0s infinite alternate-reverse;
            }

          }

          .placeholder {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            padding: 0 0 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            gap: calc(50% - 6rem);

            &[hidden] {
              display: none;
            }

            &.compact {
              .text {
                display: none;
              }
            }

            .text {
              color: #5B5B5B;
              text-align: center;
              font-size: 1.375rem;
              font-style: normal;
              font-weight: 300;
            }

            .links {
              display: flex;
              flex-direction: row;
              gap: 1.44rem;

              .link {
                flex: 1;
                border: 1px solid #979797;
                padding: 0.9rem 1rem;
                border-radius: 0.75rem;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                cursor: pointer;

                .title {
                  display: flex;
                  align-items: center;
                  gap: 1rem;
                  color: #5B5B5B;
                  font-size: 1.25rem;
                  font-style: normal;
                  font-weight: 500;

                  &::after {
                    content: '';
                    width: 1.5rem;
                    height: 1.5rem;
                    background: url('/assets/agent-forward-icon.svg') no-repeat center center;
                  }
                }

                .description {
                  color: #5B5B5B;
                  font-size: 1.125rem;
                  font-style: normal;
                  font-weight: 350;
                }
              }
            }
          }
        }
      }

      .input {
        height: 6rem;
        display: flex;
        align-items: center;
        background: #fff;
        border-radius: 3rem;
        padding: 0 1rem;
        gap: 1.7rem;

        .avatar {
          width: 4rem;
          height: 4rem;
          overflow: hidden;
          border-radius: 50%;
          background: url('/assets/avatar.png') no-repeat center center;
          background-size: contain;
        }

        .text {
          flex: 1;
          margin: 0;
          padding: 0;
          border: 0;
          outline: 0;
          background: none;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 400;
        }

        .send {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          background: url('/assets/agent-send-icon.svg') #203EBA no-repeat center center;
          display: block;
        }

      }
    }
  }
}