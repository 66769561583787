.sidebar-component {
  display: flex;
  width: 15.5rem;
  padding: 0.5rem;
  flex-direction: column;
  align-items: stretch;
  flex-shrink: 0;
  gap: 0.5rem;
  border-radius: 0.75rem;
  background: #f4f9ff;

  .user {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    gap: 0.5rem;

    .avatar {
      width: 2.5rem;
      height: 2.5rem;
      overflow: hidden;
      border-radius: 50%;
      background: url('/assets/avatar.png') no-repeat center center;
      background-size: contain;
    }
    .info {
      .name {
        color: #19161d;
        font-size: 0.875rem;
        font-weight: 500;
      }
      .role {
        color: #86888a;
        font-size: 0.75rem;
        font-weight: 400;
      }
    }
    .dropdown {
      width: 1rem;
      height: 1rem;
      background: url('/assets/sidebar-user-dropdown.svg') no-repeat center
        center;
      background-size: contain;
      margin: 0 0 0 auto;
    }
  }

  .nav {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;

    .separator {
      height: 0.0625rem;
      background: rgba(25, 22, 29, 0.06);
    }
    .group {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 0.25rem;

      .link {
        display: flex;
        align-items: center;
        padding: 0.625rem 0.75rem;
        gap: 0.5rem;
        text-decoration: none;

        .icon {
          width: 1rem;
          height: 1rem;
        }
        .text {
          color: #19161d;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.25rem;
        }
        .counter {
          display: flex;
          padding: 0 0.25rem;
          border-radius: 2.5rem;
          background: #19161d;
          color: #fff;
          text-align: center;
          font-size: 0.75rem;
          line-height: 1rem;
          font-style: normal;
          font-weight: 500;
          margin: 0 0 0 auto;
        }
        &.active {
          border-radius: 2.5rem;
          background: #fff;
          box-shadow:
            0px 0px 4px 0px rgba(0, 0, 0, 0.04),
            0px 4px 8px 0px rgba(0, 0, 0, 0.06);
        }
        &.panel {
          .icon {
            background: url('/assets/sidebar-panel-icon.svg') no-repeat center
              center;
            background-size: contain;
          }
        }
        &.orders {
          .icon {
            background: url('/assets/sidebar-orders-icon.svg') no-repeat center
              center;
            background-size: contain;
          }
        }
        &.approvals {
          .icon {
            background: url('/assets/sidebar-approvals-icon.svg') no-repeat
              center center;
            background-size: contain;
          }
        }
        &.original {
          .icon {
            background: url('/assets/sidebar-original-icon.svg') no-repeat
              center center;
            background-size: contain;
          }
        }
        &.permission {
          .icon {
            background: url('/assets/sidebar-permission-icon.svg') no-repeat
              center center;
            background-size: contain;
          }
        }
        &.team {
          .icon {
            background: url('/assets/sidebar-team-icon.svg') no-repeat center
              center;
            background-size: contain;
          }
        }
        &.account {
          .icon {
            background: url('/assets/sidebar-account-icon.svg') no-repeat center
              center;
            background-size: contain;
          }
        }
      }
    }
  }
}
