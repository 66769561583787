.header-component {
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.375rem;
  padding: 0rem 0.75rem;
  gap: 0.75rem;
  background: #2e4cc8;
  box-sizing: border-box;

  .legend {
    display: flex;
    align-items: center;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
      background: url('/assets/header-icon.svg') no-repeat center center;
      background-size: contain;
    }
    .text {
      display: flex;
      padding: 0rem 0.75rem;
      color: #f5f5f5;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .nav {
    display: flex;
    gap: 0.375rem;

    .link {
      display: flex;
      height: 1.875rem;
      align-items: center;
      color: #f5f5f5;
      font-size: 0.75rem;
      font-weight: 400;
      padding: 0rem 0.75rem;
      text-decoration: none;

      &.active {
        border-radius: 0.9375rem;
        background: #2f47af;
      }
    }
  }

  .user {
    display: flex;
    gap: 1rem;
    margin: 0 0 0 auto;

    .notification {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.875rem;
      height: 1.875rem;
      border-radius: 0.9375rem;
      background: url('/assets/header-notification.svg') #f5f5f5 no-repeat
        center center;
      background-size: contain;
    }

    .avatar {
      border-radius: 3.75rem;
      display: flex;
      width: 1.755rem;
      height: 1.755rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 0.12rem solid #fff;
      background: url('/assets/avatar.png') no-repeat center center;
      background-size: contain;
    }
  }
}
