.header-widget {

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .header-logo {
    width: 8.55156rem;
    height: 0.99631rem;
  }

  .heading {
    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    text-align: center;
  }
}