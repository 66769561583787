.context-menu-default-implementation-plugin {
  position: absolute;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  gap: 0.25rem;
  border-radius: 0.25rem;
  background: var(--white, #fff);
  box-shadow:
    0px 0px 4px 0px rgba(0, 0, 0, 0.04),
    0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  pointer-events: all;

  .group {
    .item {
      color: #000;
      font-size: 0.6875rem;
      font-weight: 400;
      text-decoration: none;
      display: block;
      padding: 0.125rem 0.25rem;

      &:hover {
        border-radius: 0.25rem;
        background: #f4f9ff;
      }
    }
  }
  .separator {
    height: 0.0625rem;
    background: rgba(25, 22, 29, 0.06);
  }
}
