.sidebar-schedule {
  margin: auto 0 0;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  & > .calendar {
    display: flex;
    padding: 0.5rem;
    gap: 0.25rem;
    border-radius: 1rem;
    background: #fff;
    box-shadow:
      0px 0px 4px 0px rgba(0, 0, 0, 0.04),
      0px 4px 8px 0px rgba(0, 0, 0, 0.06);

    .date {
      display: flex;
      width: 3.5rem;
      flex-direction: column;
      align-items: flex-start;
      flex-shrink: 0;

      .month {
        color: #53575a;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
      }
      .date {
        color: #19161d;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2rem;
      }
    }
    .events {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      .upcoming {
        display: flex;
        padding: 0.25rem 0.5rem;
        flex-direction: column;
        border-radius: 0.5rem;
        background: #5e85ea;

        .info {
          color: #fff;
          font-size: 0.75rem;
          font-weight: 500;
          line-height: 1rem;
        }
        .duration {
          color: #fff;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 1rem;
        }
        .time {
          color: #fff;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 1rem;
          opacity: 0.5;
          margin: 1rem 0 0;
        }
      }
      .next {
        display: flex;
        padding: 0.25rem 0.5rem;
        border-radius: 0.5rem;
        background: #ebabf5;
        color: #19161d;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1rem;
      }
    }
  }
  .actions {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    border-radius: 2.5rem;
    background: #fff;
    padding: 0.75rem;

    .tab {
      width: 1rem;
      height: 1rem;

      &.calendar {
        background: url('/assets/sidebar-actions-calendar-icon.svg') no-repeat
          center center;
        background-size: contain;
      }
      &.time {
        background: url('/assets/sidebar-actions-time-icon.svg') no-repeat
          center center;
        background-size: contain;
      }
      &.task {
        background: url('/assets/sidebar-actions-task-icon.svg') no-repeat
          center center;
        background-size: contain;
      }
    }
    .add {
      width: 1rem;
      height: 1rem;
      margin: 0 0 0 auto;
      background: url('/assets/sidebar-actions-add-icon.svg') no-repeat center
        center;
      background-size: contain;
    }
  }
}
