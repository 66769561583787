.document-builder-comment-overlay {
  position: absolute;
  display: none;
  flex-direction: column;
  padding: 0.5rem;
  gap: 0.5rem;
  width: 18rem;
  border-radius: 0.5rem;
  border: 1px solid #EBEBEB;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);

  &.shown {
    display: flex;
  }

  .header {
    height: 2rem;
    line-height: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0.5rem;
    border-bottom: 1px solid #E5E5E5;

    .title {
      color: #4D4D4D;
      font-size: 0.75rem;
      font-weight: 400;
      letter-spacing: 0.0125rem;
    }

    .actions {
      display: flex;
      gap: 0.5rem;

      .action {
        width: 1rem;
        height: 1rem;
        border-radius: 1.875rem;
        display: block;

        &.resolve {
          background: url('/assets/document-builder/comment-resolve-icon.svg') #ccc no-repeat center center;
        }

        &.close {
          background: url('/assets/document-builder/comment-close-icon.svg') #ccc no-repeat center center;
        }
      }
    }
  }

  .comments {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .comment {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .user {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: #4D4D4D;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.0125rem;

        .avatar {
          width: 2rem;
          height: 2rem;
          background: url('/assets/document-builder/comment-avatar.png') no-repeat center center;
        }
      }

      .content {
        color: #000;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.0125rem;
      }
    }
  }

  .edit {
    padding: 0.5rem;
    display: flex;
    border-radius: 0.5rem;
    border: 1px solid #e5e5e5;
    background: #fff;

    .text {
      flex: 1;
      border: 0;
      padding: 0;
      margin: 0;
      outline: 0;
      color: #53575A;
      font-size: 0.75rem;
      font-weight: 400;
      letter-spacing: 0.0125rem;
    }

    .send {
      width: 1rem;
      height: 1rem;
      border-radius: 1.25rem;
      background: url('/assets/document-builder/comment-send-icon.svg') #000 no-repeat center center;
    }
  }
}