html,body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #2E4CC8;
  font-size: 16px;
}

#root {
  width: 100%;
  height: 100%;
}
