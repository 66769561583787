.data-identity-overlay {
  display: flex;
  padding: 0rem 0.75rem;
  flex-direction: column;
  gap: 0.75rem;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow:
    0px 0px 4px 0px rgba(0, 0, 0, 0.04),
    0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  width: 20rem;

  .title {
    color: #000;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 2rem;
    padding: 0.875rem 0 0.69rem;
    border-bottom: 1px solid #e5e5e5;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .field {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .label {
        min-width: 6rem;
        text-align: right;
      }
    }
  }

  .actions {
    border-top: 1px solid #e5e5e5;
    padding: 0.69rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;

    .button {
      height: 2rem;
      line-height: 2rem;
      padding: 0 0.75rem;
      border-radius: 2.5rem;
      font-size: 0.875rem;
      font-weight: 400;
      letter-spacing: 0.0125rem;
      display: block;
      text-decoration: none;

      &.cancel {
        background: #f2f2f2;
        color: #19161d;
      }

      &.submit {
        background: #19161d;
        color: #fff;
      }
    }
  }
}