.text-widget {
  outline: 0;
  color: #3e3e3e;
  font-size: 0.75rem;
  font-weight: 400;

  &:empty::after {
    content: '请输入文本';
    color: #a8a8a8;
  }

  &.current-layer:empty::after {
    display: none;
  }
}