.document-builder-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;

  .body {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    overflow: hidden;

    .document-builder-library {
      z-index: 9;
    }

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      background: linear-gradient(180deg, #ccdbfd 0%, #96adec 100%);
      border-radius: 0.75rem;

      .title {
        display: flex;
        height: 4.5rem;
        padding: 0.1875rem 0.375rem;
        flex-direction: row;
        align-items: center;

        .legend {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;

          .text {
            color: #19161d;
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 2rem;
          }

          .add {
            border-radius: 1.75rem;
            box-shadow:
              0px 0px 2.8px 0px rgba(0, 0, 0, 0.04),
              0px 2.8px 5.6px 0px rgba(0, 0, 0, 0.06);
            width: 1.75rem;
            height: 1.75rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 1.75rem;
          }
        }

        .primary {
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2.5rem;
          box-shadow:
            0px 0px 4px 0px rgba(0, 0, 0, 0.04),
            0px 4px 8px 0px rgba(0, 0, 0, 0.06);
          margin: 0 0 0 auto;
        }
      }

      .ribbon {
        display: flex;
        gap: 0.5rem;

        .tabbar {
          display: flex;
          flex: 1;
          height: 1.88rem;
          padding: 0rem 0.75rem;
          justify-content: space-between;
          align-items: center;
          border-radius: 0.75rem;
          background: #fff;
          box-shadow:
            0px 0px 4px 0px rgba(0, 0, 0, 0.04),
            0px 4px 8px 0px rgba(0, 0, 0, 0.06);

          .tabs {
            display: flex;
            align-items: center;

            .tab {
              padding: 0rem 0.375rem 0 0.75rem;
              font-size: 0.75rem;
              font-weight: 400;
              display: flex;
              align-items: center;
              gap: 0.25rem;
              text-decoration: none;
              color: #4d4d4d;
              margin: 0 0 0 0.375rem;
              border-left: 1px solid #4d4d4d;
              cursor: pointer;

              .close {
                width: 0.5rem;
                height: 0.5rem;
                padding: 0.2rem;
                background: url('/assets/document-builder/editor-close-icon.svg') no-repeat center center;
                display: none;
              }

              &:first-of-type {
                border-left: 0;
                margin: 0;
                padding: 0 0.375rem;
              }

              &.active {
                border-left: 0;
                margin: 0;
                padding: 0 0.375rem;
                border-radius: 0.5rem;
                background: #cddcfe;
                line-height: 1.5rem;

                .close {
                  display: block;
                }
              }
            }
          }

          .zoom {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;

            .value {
              color: #4d4d4d;
              font-size: 0.75rem;
              font-weight: 400;
            }

            .actions {
              display: flex;
              gap: 0.75rem;
              margin: 0 0 0 0.39rem;

              .action {
                width: 0.9rem;
                height: 0.9rem;

                &.in {
                  background: url('/assets/document-builder/editor-zoom-in-icon.svg') no-repeat center center;
                }

                &.out {
                  background: url('/assets/document-builder/editor-zoom-out-icon.svg') no-repeat center center;
                }
              }
            }
          }
        }
      }

      &.editor {
        padding: 0.5rem 0.5rem 0.5rem 2.5rem;
        margin: 0 0 0 -1.88rem;
        overflow: hidden;

        .title {
          .add {
            background: url('/assets/document-builder/editor-add-icon.svg') #f5f5f5 no-repeat center center;
          }

          .unfold {
            background: url('/assets/document-builder/editor-fold-icon.svg') #19161d no-repeat center center;
          }
        }

        .comment-pin {
          width: 2rem;
          height: 2rem;
          border-radius: 6.25rem;
          background: url('/assets/document-builder/comment-bubble-icon.svg') #DA22F8 no-repeat center center;
          transform: translateX(-2.5rem) translateY(-0.5rem);
          display: block;
        }

        .preview {
          flex: 1;
          width: 39.25rem;
          border-radius: 0.5rem 0.5rem 0rem 0rem;
          background: #fff;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: auto;
          padding: 2.5rem 1.88rem 0;
          font-family: 'SourceHanSans-Normal', sans-serif;

          .column-layout-widget {
            gap: 1.5rem;

            .column-layout-widget {
              gap: 0;
            }
          }

          .placeholder {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.25rem;

            .icon {
              width: 9.75rem;
              height: 9.35244rem;
              background: url('/assets/document-builder/editor-placeholder-icon.svg') no-repeat center center;
            }

            .text {
              color: #0d52ac;
              text-align: center;
              font-size: 0.875rem;
              font-weight: 400;
              letter-spacing: 0.0125rem;
            }
          }
        }
      }

      &.original {
        padding: 0.5rem 0;
        flex: 0;
        overflow: hidden;
        transition: all 0.1s ease-in-out;

        .title {
          .add {
            background: url('/assets/document-builder/editor-add-icon.svg') #f5f5f5 no-repeat center center;
          }

          .fold {
            background: url('/assets/document-builder/editor-fold-icon.svg') #19161d no-repeat center center;
            transform: rotate(180deg);
          }
        }

        .ribbon {
          gap: 0.25rem;

          .name {
            color: #000;
            font-size: 0.75rem;
            font-weight: 400;
            height: 1.88rem;
            line-height: 1.88rem;
            padding: 0 0.75rem;
            border-radius: 0.75rem 0 0 0.75rem;
            box-shadow:
              0px 0px 4px 0px rgba(0, 0, 0, 0.04),
              0px 4px 8px 0px rgba(0, 0, 0, 0.06);
            background: #fff;
          }

          .tabbar {
            border-radius: 0 0.75rem 0.75rem 0;
          }
        }

        .list {
          display: flex;
          flex-direction: column;
          padding: 0.75rem 0 0;
          flex: 1;
          border-radius: 0.5rem;
          background: #fff;

          .item {
            height: 3rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            padding: 0 0.75rem 0 1.13rem;

            .no {
              color: #3e3e3e;
              font-size: 0.75rem;
              font-weight: 400;
            }

            .icon {
              width: 1.5rem;
              height: 1.5rem;
            }

            .label {
              display: flex;
              flex-direction: column;
              gap: 0.25rem;

              .name {
                color: #3e3e3e;
                font-size: 0.625rem;
                font-weight: 700;
              }

              .date {
                color: #3e3e3e;
                font-size: 0.625rem;
                font-weight: 400;
              }
            }

            .size {
              padding: 0 0.625rem;
              height: 1.8rem;
              line-height: 1.8rem;
              margin: 0 0 0 auto;
              border-radius: 5.20831rem;
              border: 1px solid #3e3e3e;
              color: #3e3e3e;
              font-size: 0.67706rem;
              font-weight: 400;
            }

            &.audio {
              .icon {
                background: url('/assets/document-builder/editor-audio-icon.svg') no-repeat center center;
              }
            }
          }
        }
      }
    }

    &.unfold .editor .title .unfold {
      display: none;
    }

    &.unfold .original {
      flex: 1;
      padding: 0.5rem;
    }
  }
}