.data-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  background: linear-gradient(180deg, #ccdbfd 0%, #96adec 100%);

  .body {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 0.25rem;

    main {
      flex: 1;
      display: flex;
      padding: 0.5rem;
      flex-direction: column;
      gap: 0.5rem;

      .title {
        display: flex;
        height: 4.5rem;
        padding: 0.1875rem 0.375rem;
        flex-direction: row;
        align-items: center;

        .text {
          color: #19161d;
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 2rem;
        }

        .add {
          width: 2rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2.5rem;
          background: url('/assets/data-load-icon.svg') #ffffff no-repeat center center;
          box-shadow:
            0px 0px 4px 0px rgba(0, 0, 0, 0.04),
            0px 4px 8px 0px rgba(0, 0, 0, 0.06);
          margin: 0 0 0 1rem;
        }
      }

      .table {
        display: grid;
        flex: 1;
        border-radius: 0.5rem;
        background: #FFF;
        padding: 1rem;
      }
    }
  }
}