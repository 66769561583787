.template-builder-data-overlay-mask {
  background: #00000099;
  backdrop-filter: none;

  .template-builder-data-overlay {
    display: flex;
    max-height: 50vh;
    padding: 1rem;
    flex-direction: column;
    gap: 0.625rem;
    border-radius: 0.75rem;
    background: var(--white-white-100, #FFF);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);

    .search {
      display: flex;
      align-items: center;
      min-height: 2.5rem;
      line-height: 2.5rem;
      padding: 0 0.5rem;
      gap: 0.5rem;
      border-radius: 2.5rem;
      border: 1px solid var(--Grey-Grey-300, #EBEBEB);
      background: var(--white-white-100, #FFF);

      .icon {
        background: url('/assets/template-builder/library-search-icon.svg') no-repeat center center;
        width: 1rem;
        height: 1rem;
      }

      .text {
        outline: 0;
        border: none;
        font-size: 0.875rem;
        font-weight: 400;
        letter-spacing: 0.0125rem;

        &::placeholder {
          color: #53575A;
        }
      }
    }

    .fields-border {
      border-radius: 0.5rem;
      border: 1px solid #000;
      overflow: auto;

      .fields {
        width: 100%;
        height: 100%;
        border-spacing: 0;
        border-collapse: collapse;

        td {
          border: 1px solid #000;
          padding: 0.5rem 1rem;
          color: #19161D;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0.0125rem;

          &:first-of-type {
            border-left: 0;
          }

          &:last-of-type {
            border-right: 0;
          }

          &.checkbox {
            width: 2.25rem;
            height: 2.25rem;
            box-sizing: border-box;
            background: url('/assets/template-builder/data-checkbox-icon.svg') no-repeat center center;
          }
        }

        tr {
          cursor: pointer;

          &:first-of-type {
            td {
              border-top: 0;
            }
          }

          &:last-of-type {
            td {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}