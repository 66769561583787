.data-widget {
  outline: 0;
  color: #3e3e3e;
  font-size: 0.75rem;
  font-weight: 400;
  min-height: 1rem;

  // &:empty::before {
  //   content: '请选择数据字段';
  //   font-style: italic;
  //   color: #ccc;
  // }
}