.sign-in-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: url('/assets/signin-background.svg') #E6EEFD no-repeat center center;
  background-size: cover;

  .logo {
    width: 20.4rem;
    height: 3.75rem;
    margin: 1.25rem 0 0 2.19rem;
    background: url('/assets/signin-logo.svg') no-repeat center center;
    background-size: contain;
  }

  .layout {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5.44rem;

    .banner {
      display: flex;
      flex-direction: column;
      gap: 2.63rem;

      .slogan {
        display: flex;
        flex-direction: column;
        align-items: center;

        .heading {
          color: #1E3BB3;
          font-size: 2rem;
          font-style: normal;
          font-weight: 600;
        }

        .text {
          color: #787879;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
        }
      }

      .image {
        width: 44.8125rem;
        height: 31.375rem;
        background: url('/assets/signin-banner.png') no-repeat center center;
        background-size: contain;
      }
    }

    .form {
      border-radius: 0.75rem;
      background: #fff;
      width: 28.5rem;
      padding: 1.875rem;

      .title {
        color: #000;
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
      }

      .greeting {
        color: #808080;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        margin: 2.25rem 0 3rem;
      }

      .fields {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .field {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .label {
            color: #333;
            font-size: 0.6875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 0.75rem;
            padding: 0 1rem;
          }

          .text {
            height: 3rem;
            border-radius: 0.5rem;
            border: 1px solid rgba(30, 59, 179, 0.24);
            color: #808080;
            font-size: 0.9375rem;
            font-style: normal;
            font-weight: 400;
            padding: 0 1rem;
            outline: 0;

            input::placeholder,
            &::placeholder {
              color: #808080;
            }
          }
        }
      }

      .extra {
        margin: 1.25rem 0 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .remember {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;

          .label {
            color: #1A1A1A;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.01875rem;
          }
        }

        .forgot {
          color: #007AFF;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0.01875rem;
          text-decoration: none;
        }
      }

      .button {
        display: block;
        text-align: center;
        border-radius: 0.5rem;
        background: #1E3BB3;
        padding: 0.625rem 1.5rem;
        color: #fff;
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem;
        letter-spacing: 0.01875rem;
        text-decoration: none;
        margin: 2rem 0;

      }

      .hint {
        color: #000;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        letter-spacing: 0.01875rem;
        padding: 1.5rem 0 0;
        border-top: 1px solid #E5E5E5;
        text-align: center;
      }
    }

  }
}