.flag-icon-widget {
  width: 1.125rem;
  height: 1.125rem;
  display: block;

  // &.editing {
  //   background: url('/assets/widget/flag-icon-forbidden.png') no-repeat center center;
  // }

  // &.pass {
  //   background: url('/assets/widget/flag-icon-checked.png') no-repeat center center;
  // }

  // &.fail {
  //   background: url('/assets/widget/flag-icon-error.png') no-repeat center center;
  //   border-radius: 6.25rem;
  // }
}